import dayjs from "dayjs";

function addBusinessDays(date, numberOfDays) {
  const holidays = [
    "2023-01-01", // New Year's Day
    "2023-01-02", // Second day of New Year
    "2023-01-07", // Orthodox Christmas
    "2023-01-19", // Orthodox Epiphany
    "2023-03-03", // Mother's Day
    "2023-03-08", // International Women's Day
    "2023-04-09", // Day of National Unity
    "2023-05-12", // St. Andrew the First-Called Day
    "2023-05-26", // Independence Day of Georgia
    "2023-08-28", // Assumption of Mary
    "2023-10-14", // Svetitskhovloba (Mtskhetoba)
    "2023-11-23", // St. George's Day
  ];

  let currentDate = dayjs(date);
  let addedDays = 0;

  while (addedDays < numberOfDays) {
    // Advance to the next day
    currentDate = currentDate.add(1, "day");

    // Check if the current day is not Saturday (6) or Sunday (0)
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      // Also ensure it's not a holiday
      if (!holidays.includes(currentDate.format("YYYY-MM-DD"))) {
        addedDays++; // It's a valid business day
      }
    }
  }

  return currentDate;
}

export default addBusinessDays;
