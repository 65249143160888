import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  DatePicker,
  InputNumber,
  List,
  Avatar,
  Row,
  Col,
  Flex,
  Alert,
} from "antd";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import ru from "@fullcalendar/core/locales/ru";
import "dayjs/locale/ru";
import { ShoppingCart, Truck } from "react-feather";

import addBusinessDays from "../../utils/addBusinessDays";
import { GiCheckMark, GiDrill, GiCircularSawblade } from "react-icons/gi";
import { FaHandPaper } from "react-icons/fa";

const ProductionTimeline = ({ days, contractDate, status }) => {
  const [loaded, setLoaded] = useState(false);
  dayjs.locale("ru");
  const [startDate, setStartDate] = useState(
    (contractDate && dayjs(contractDate)) || dayjs(),
  ); // Initial order date
  const [totalDays, setTotalDays] = useState(days || 15); // Total duration of the order

  // Fixed days for certain stages
  const fixedDays = 3;
  const dynamicDays = totalDays - fixedDays;

  // Calculate dynamic stage durations
  const designDays = Math.floor(dynamicDays * 0.4);
  const assemblyDays = Math.floor(dynamicDays * 0.4);
  const installationDays = Math.ceil(dynamicDays * 0.2);

  // Fixed durations for other stages
  const procurementDays = 1;
  const cuttingCladdingDays = 1;
  const deliveryDays = 1;

  // Calculate end dates for each stage
  const designEndDate = addBusinessDays(startDate, designDays);
  const procurementEndDate = addBusinessDays(designEndDate, procurementDays);
  const cuttingCladdingEndDate = addBusinessDays(
    procurementEndDate,
    cuttingCladdingDays,
  );
  const assemblyEndDate = addBusinessDays(cuttingCladdingEndDate, assemblyDays);
  const deliveryEndDate = addBusinessDays(assemblyEndDate, deliveryDays);
  const installationEndDate = addBusinessDays(
    deliveryEndDate,
    installationDays,
  );

  // Data for the list and calendar events
  const stages = [
    {
      title: "Конструктив",
      endDate: designEndDate,
      color: "#f56a00",
      icon: <FaHandPaper />,
    },
    {
      title: "Закупка",
      endDate: procurementEndDate,
      color: "#7265e6",
      icon: <ShoppingCart />,
    },
    {
      title: "Резка/Оклейка",
      endDate: cuttingCladdingEndDate,
      color: "#ffbf00",
      icon: <GiCircularSawblade />,
    },
    {
      title: "Сборка",
      endDate: assemblyEndDate,
      color: "#00a2ae",
      icon: <GiDrill />,
    },
    {
      title: "Доставка",
      endDate: deliveryEndDate,
      color: "#f04134",
      icon: <Truck />,
    },
    {
      title: "Установка",
      endDate: installationEndDate,
      color: "#00a854",
      icon: <GiCheckMark />,
    },
  ];

  const calendarEvents = stages.map((stage) => ({
    title: stage.title,
    date: stage.endDate.format("YYYY-MM-DD"),
    color: stage.color,
  }));

  useEffect(() => {
    setLoaded(true);
  }, []);

  const currentStageIndex = stages.findIndex(
    (stage) => stage.status === status,
  );
  const isDelayed = dayjs().isAfter(stages[currentStageIndex || 0]?.endDate);

  return (
    <div>
      {isDelayed && (
        <Alert
          message="Опасность!!!"
          description={`Статус заказа не соотвествует дате, есть вероятность что не уложимся в срок!`}
          type="error"
          showIcon
          style={{ margin: "20px 0" }}
        />
      )}
      <Row gutter={24}>
        <Col lg={6}>
          <div style={{ marginBottom: "20px" }}>
            <Flex gap={12}>
              <DatePicker
                value={dayjs(startDate)}
                onChange={(date) => setStartDate(date)}
                style={{ width: "100%" }}
              />
              <InputNumber
                min={1}
                value={totalDays}
                onChange={(value) => setTotalDays(value)}
                style={{ width: "100%" }}
              />
            </Flex>
          </div>
          <List
            bordered
            dataSource={stages}
            renderItem={(stage) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: stage.color, padding: 4 }}
                      icon={stage.icon}
                      size={48}
                    />
                  }
                  title={`${stage.endDate.format("DD MMM YYYY")}`}
                  description={`${stage.title}`}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col lg={18}>
          {loaded && (
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              locale={ru}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductionTimeline;
