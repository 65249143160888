import React, { useEffect, useState } from "react";
import { Table, Divider, Avatar, Flex, Input, Card } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import useData from "../../hooks/useData";
import extractName, {
  convertTableToVertical,
  getColorFromInitials,
  getInitials,
} from "../../utils/extractName";
import "sweetalert2/dist/sweetalert2.min.css";
import useWindowSize from "../../hooks/useWindowSize";
const Employees = () => {
  const [filteredData, setFilteredData] = useState([]);

  const { data: list } = useData("/products/users");
  // const { data: designers } = useData("/products/designers");

  const { width } = useWindowSize();
  const { i18n } = useTranslation();

  const { language } = i18n;
  dayjs.locale(language);

  const getName = (id) => {
    return list?.find((user) => user._id === id)?.name;
  };

  useEffect(() => {
    setFilteredData(list);
  }, [list]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = list.filter((item) =>
      tableColumns.some((col) => {
        const cellValue = item[col.dataIndex];
        return (
          cellValue && cellValue.toString().toLowerCase().includes(searchValue)
        );
      }),
    );
    setFilteredData(filtered);
  };

  const tableColumns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Flex gap={6} align={"center"}>
          <Avatar
            style={{
              backgroundColor: getColorFromInitials(
                getInitials(extractName(record.name)),
              ),
            }}
          >
            {getInitials(extractName(record.name))}
          </Avatar>
          {getName(record._id)}
        </Flex>
      ),
      filters: list?.map((user) => ({ text: user.name, value: user._id })),
      onFilter: (value, record) => record._id === value,
      filterSearch: true,
    },
    {
      title: "Эл. почта",
      dataIndex: "email",
      key: "email",
      render: (text) => text,
    },
    {
      title: "Телефон",
      dataIndex: "mobile",
      key: "phone",
      render: (text) => <a href={`tel:+995${text}`}>{text}</a> || "-",
    },
    {
      title: "Дата рождения",
      dataIndex: "birthDate",
      key: "birtDate",
      render: (text) => dayjs(text).subtract(1, "day").format("DD MMMM YYYY"),
    },
    {
      title: "День рождения",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (date) => {
        const birthdayDate = dayjs(date).subtract(1, "day"); // Используем вашу дату в ISO формате
        const today = dayjs();

        // Определяем дату следующего дня рождения в текущем году
        const thisYearBirthday = birthdayDate.year(today.year());

        // Если день рождения уже прошёл в этом году, устанавливаем дату на следующий год
        const nextBirthday = thisYearBirthday.isBefore(today, "day")
          ? thisYearBirthday.add(1, "year")
          : thisYearBirthday;

        // Вычисляем разницу в месяцах и днях
        let monthsUntilBirthday = nextBirthday.diff(today, "month");
        const daysUntilBirthday = nextBirthday.diff(
          today.add(monthsUntilBirthday, "month"),
          "day",
        );

        // Возвращаем либо месяцы, либо дни
        if (monthsUntilBirthday > 0) {
          return `${monthsUntilBirthday} месяцев и ${daysUntilBirthday} дней`;
        } else {
          return `${daysUntilBirthday} дней`;
        }
      },
    },
    {
      title: "Возраст",
      dataIndex: "birthDate",
      key: "age",
      render: (date) => {
        const birthdayDate = dayjs(date);
        const today = dayjs();
        return `${today.diff(birthdayDate, "year")} лет`;
      },
    },
  ].filter(Boolean);

  const data = convertTableToVertical({
    columns: tableColumns,
    dataSource: filteredData,
    size: width < 1024 ? "xs" : undefined,
  });

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Card className={"mb-2 pb-0"}>
            <Input.Search
              placeholder="Поиск"
              size={"large"}
              onSearch={handleSearch}
              style={{ marginBottom: 16 }}
            />
          </Card>
          {list && (
            <div className="table-responsive">
              {data?.dataSource?.map((record, index) => (
                <React.Fragment key={index}>
                  <Table
                    columns={data.columns}
                    dataSource={record}
                    pagination={record?.length > 20}
                    scroll={{ x: "max-content" }}
                    showHeader={width >= 1024}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Employees;
