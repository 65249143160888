import React, { useState } from "react";
import AddPurchases from "../../core/modals/purchases/addpurchases";
import ImportPurchases from "../../core/modals/purchases/importpurchases";
import EditPurchases from "../../core/modals/purchases/editpurchases";
import { Table, Image, Badge, Tag } from "antd";
import useData from "../../hooks/useData";
import NumberFormat from "../../utils/NumberFormat";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";
import customRound from "../../utils/customRound";
const PurchaseStatus = {
  PENDING: <Badge status="warning" text="Не начато" />,
  DONE: <Badge status="success" text="Куплено" />,
  PARTIAL: <Badge status="processing" text="В процессе" />,
};

const ProductTable = ({ products, id, update }) => {
  const { _id } = useAuthUser();

  const [selectedProducts, setSelectedProducts] = useState(
    products.filter((v) => !!v?.selected).map((v) => v.name),
  );
  const rowClassName = (record) => {
    // Apply the "selected-row" class if the row is selected
    return selectedProducts.includes(record.name) ? "selected-row" : "";
  };

  const handleUpdateStatus = async (record, status) => {
    if (status) {
      await update(
        {
          id,
          status,
          productId: record._id,
          purchase: {
            date: new Date(),
            by: _id,
          },
        },
        "/purchase/updateStatus",
      );
    } else {
      await update(
        { id, status, productId: record._id },
        "/purchase/updateStatus",
      );
    }
  };
  return (
    <Table
      rowKey="name"
      key="value"
      rowClassName={rowClassName}
      rowSelection={{
        columnTitle: "Куплено",
        columnWidth: 30,
        checkStrictly: true,
        onSelect: async (record, selected, selectedRows) => {
          setSelectedProducts(selectedRows.map((v) => v.name));
          await handleUpdateStatus(record, selected);
        },
        defaultSelectedRowKeys: selectedProducts,
        selectedRowKeys: selectedProducts,
      }}
      columns={[
        {
          title: "Название продукта",
          dataIndex: "name",
          key: "name",
          render: (text, record) => (
            <>
              <div style={{ display: "flex", gap: 24 }}>
                <Image
                  src={record.file}
                  alt={text}
                  width={30}
                  height={30}
                  style={{
                    background: "#FFF",
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                    objectPosition: "center",
                    marginRight: 24,
                  }}
                />
                {text}
                {record.code && (
                  <>
                    <br />{" "}
                    <Tag>
                      <Icon.Tag size={12} /> {record.code}
                    </Tag>
                  </>
                )}
              </div>
            </>
          ),
        },
        { title: "Ед. измерения", dataIndex: "unit", key: "unit" },
        {
          title: "Цена",
          dataIndex: "price",
          key: "price",
          render: (text) => `${NumberFormat(text)} ₾`,
        },
        {
          title: "Кол-во",
          dataIndex: "quantity",
          key: "quantity",
          render: (text, product) => {
            return product?.unitId !== "66428b8c5a60f8cde1e5cb1b"
              ? `${customRound(text / product?.value)}`
              : text;
          },
        },
        {
          title: "Дата закупки",
          dataIndex: "purchase",
          key: "date",
          render: (text, record) =>
            record?.purchase?.date
              ? dayjs(new Date(record?.purchase?.date)).format("D MMMM HH:mm")
              : "-",
        },
      ]}
      dataSource={products}
      pagination={false}
    />
  );
};

const SupplierTable = ({ suppliers, id, update }) => (
  <Table
    key={"222"}
    columns={[
      {
        title: "Поставщик",
        width: 80,
        dataIndex: "dealer",
        key: "dealer",
        fixed: "left",
      },
      {
        width: 40,
        title: "Куплено",
        key: "count",
        render: (text, record) =>
          `${record.products.filter((v) => !!v?.selected)?.length || 0}/${record.products?.length || 0}`,
      },
      {
        width: 120,
        title: "Статус",
        key: "status",
        render: (text, record) => {
          const count =
            record.products.filter((v) => !!v?.selected)?.length || 0;
          if (count === 0) {
            return PurchaseStatus.PENDING;
          } else if (count === record.products?.length) {
            return PurchaseStatus.DONE;
          } else {
            return PurchaseStatus.PARTIAL;
          }
        },
      },
      {
        title: "Итого",
        key: "",
        render: (text, record) =>
          `${NumberFormat(
            record.products.reduce(
              (acc, v) =>
                acc + v.unit === "66428b8c5a60f8cde1e5cb1b"
                  ? v?.price * v.quantity
                  : (v.price * v.quantity) / (v?.value || 1),
              0,
            ),
          )} ₾`,
      },
    ]}
    dataSource={suppliers}
    pagination={false}
    expandable={{
      expandedRowRender: (supplier) => (
        <ProductTable products={supplier.products} id={id} update={update} />
      ),
    }}
    rowKey="dealer"
  />
);

const PurchasesList = () => {
  const { data, isLoading, update } = useData("/purchase/list", []);
  const {
    i18n: { language },
  } = useTranslation();
  dayjs.locale(language);

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    { title: "Контракт", dataIndex: "contract", key: "contract" },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const count = record.selectedProducts.reduce((acc, item) => {
          return acc + item.products.length;
        }, 0);
        const selected = record.selectedProducts.reduce((acc, item) => {
          return acc + item.products.filter((v) => v?.selected).length;
        }, 0);
        return count === selected
          ? PurchaseStatus.DONE
          : selected === 0
            ? PurchaseStatus.PENDING
            : PurchaseStatus.PARTIAL;
      },
    },
    {
      title: "Кол-во продуктов",
      dataIndex: "selectedProducts",
      key: "selectedProducts",
      render: (text, record) =>
        `${record.selectedProducts.reduce((acc, item) => acc + item.products.filter((v) => v?.selected)?.length, 0)}/${record.selectedProducts.reduce((acc, item) => acc + item.products.length, 0)}`,
    },
    {
      title: "Когда надо купить",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => dayjs(new Date(text)).format("D MMMM"),
    },
    { title: "Автор", dataIndex: "author", key: "author" },
    { title: "Дата создания", dataIndex: "date", key: "date" },
    { title: "Комментарий", dataIndex: "comment", key: "comment" },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase List</h4>
                <h6>Manage your purchases</h6>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table
                  key={"123"}
                  columns={columns}
                  dataSource={data}
                  expandable={{
                    expandedRowRender: (record) => (
                      <SupplierTable
                        suppliers={record.selectedProducts}
                        id={record._id}
                        update={update}
                        key={record._id}
                      />
                    ),
                    rowExpandable: (record) =>
                      record.selectedProducts.length > 0,
                  }}
                  rowKey="name"
                  loading={isLoading}
                  pagination={false}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddPurchases />
      <ImportPurchases />
      <EditPurchases />
    </div>
  );
};

export default PurchasesList;
