import React, { useEffect, useMemo, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  Dropdown,
  Modal,
  Table,
  Tabs,
  Button,
  Timeline,
  Flex,
  Spin,
  Input,
  Tag,
  Divider,
  DatePicker,
  InputNumber,
  Card,
  Row,
  Col,
  Statistic,
  Collapse,
  Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import NumberFormat from "../../utils/NumberFormat";
import useData from "../../hooks/useData";
import { CaretRightOutlined, EllipsisOutlined } from "@ant-design/icons";
import {
  GiCheckMark,
  GiCircularSawblade,
  GiDrill,
  GiFinishLine,
  GiTruck,
} from "react-icons/gi";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { BiPencil, BiUser } from "react-icons/bi";
import { FaMessage, FaPencil } from "react-icons/fa6";
import addBusinessDays from "../../utils/addBusinessDays";
import SelectUser from "../../Components/SelectUser/SelectUser";
import extractName, {
  convertTableToVertical,
  getUserData,
} from "../../utils/extractName";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import useWindowSize from "../../hooks/useWindowSize";
import { CgFileDocument } from "react-icons/cg";
import { FcMoneyTransfer } from "react-icons/fc";
import { Plus } from "react-feather";
import ProductionTimeline from "../../Components/ProductionTimeline";
const SalesList = () => {
  const { _id, permissions } = useAuthUser();
  const [edit, setEdit] = useState();
  const [comment, setComment] = useState("");
  const [history, setHistory] = useState({ history: [], record: null });
  const [contract, setContract] = useState({ payments: [], record: null });
  const [teamContract, setTeamContract] = useState({
    payments: [],
    record: null,
  });
  const [open, setOpen] = useState(0);
  const [loading, setIsLoading] = useState({
    id: null,
    loading: false,
  });

  const [paymentAmount, setPaymentAmount] = useState("");
  const [teamPaymentAmount, setTeamPaymentAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState(dayjs().toDate());
  const [list, setFilteredData] = useState([]);

  const { data: loadData, refetch } = useData("/sales/contracts");
  const { data: teams } = useData("/products/users");
  const { data: designers } = useData("/products/designers");
  const { update, isMutating } = useData("/products/invoices", []);
  const { update: updateHistory, isMutating: isM } = useData(
    "/products/history",
    [],
  );

  const { update: addPayment, isMutating: saveIsLoading } = useData(
    "/products/designerPaid",
    [],
  );

  const { update: addTeamPayment, isMutating: saveTeamIsLoading } = useData(
    "/products/teamPaid",
    [],
  );

  const { width } = useWindowSize();

  const [selectedTab, setSelectedTab] = useState(1);
  const { t, i18n } = useTranslation();
  const [action, setAction] = useState({
    type: null,
    data: null,
  });

  const { language } = i18n;
  dayjs.locale(language);

  const changeStatus = async (id, status) => {
    const result = await Swal.fire({
      title: `Вы уверени что хотите перевести в статус ${t("sales.contractStatus." + status)}?`,
      text: "Это действие невозможно отменить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Изменить статус",
      cancelButtonText: "Отмена",
    });

    if (result.isConfirmed) {
      setIsLoading({ id, loading: true });
      await update({ id, status }, `/addEdit?table=invoices`);
      await updateHistory(
        { contractId: id, status, date: new Date(), author: _id },
        `/addEdit?table=history`,
      );
      await refetch();
      await Swal.fire({
        title: "Успешно!",
        text: "Статус изменен",
        icon: "success",
      });
    }
  };

  useEffect(() => {
    if (isM === false && !isMutating) {
      setIsLoading({ id: null, loading: false });
    }
  }, [isM, isMutating]);

  const isLoading = (id) => loading.id === id && loading.loading;

  const showHistory = (history, record) => {
    setHistory({ history, record });
  };

  const getStatusButton = (status, id) => {
    if (!status) {
      return (
        <Button
          size={"small"}
          icon={<GiCircularSawblade />}
          onClick={() => changeStatus(id, "cutting")}
          loading={isLoading(id)}
        >
          В работу
        </Button>
      );
    }
    if (status === "cutting") {
      return (
        <Button.Group>
          <Button icon={<GiCircularSawblade />} type={"dashed"} size={"small"}>
            {t(`sales.contractStatus.${status}`)}
          </Button>
          <Button
            onClick={() => changeStatus(id, "assembly")}
            icon={<CaretRightOutlined />}
            size={"small"}
            title={`перевести в ${t("sales.contractStatus.assembly")}`}
            loading={isLoading(id)}
          />
        </Button.Group>
      );
    }
    if (status === "assembly") {
      return (
        <Button.Group>
          <Button icon={<GiDrill />} type={"dashed"} size={"small"}>
            {t(`sales.contractStatus.${status}`)}
          </Button>
          <Button
            onClick={() => changeStatus(id, "delivery")}
            icon={<CaretRightOutlined />}
            size={"small"}
            title={`перевести в ${t("sales.contractStatus.delivery")}`}
            loading={isLoading(id)}
          />
        </Button.Group>
      );
    }
    if (status === "delivery") {
      return (
        <Button.Group size={"small"}>
          <Button icon={<GiTruck />} type={"dashed"} size={"small"}>
            {t(`sales.contractStatus.${status}`)}
          </Button>
          <Button
            onClick={() => changeStatus(id, "installation")}
            icon={<CaretRightOutlined />}
            size={"small"}
            title={`перевести в ${t("sales.contractStatus.installation")}`}
            loading={isLoading(id)}
          />
        </Button.Group>
      );
    }
    if (status === "installation") {
      return (
        <Button.Group>
          <Button icon={<GiFinishLine />} size={"small"} type={"dashed"}>
            {t(`sales.contractStatus.${status}`)}
          </Button>
          <Button
            onClick={() => changeStatus(id, "completed")}
            size={"small"}
            icon={<CaretRightOutlined />}
            title={`перевести в ${t("sales.contractStatus.completed")}`}
            loading={isLoading(id)}
          />
        </Button.Group>
      );
    }

    if (status === "completed") {
      return (
        <Tag color={"green"}>
          <GiCheckMark /> {t(`sales.contractStatus.${status}`)}
        </Tag>
      );
    }
  };

  const getName = (id) => {
    return teams?.find((user) => user._id === id)?.name;
  };

  const items = useMemo(
    () => [
      {
        key: "1",
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/ge.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            ქართული
          </>
        ),
        children: (
          <iframe
            name="contract-0"
            id="contract-0"
            src={`https://www.presta.ge/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
      {
        disabled: !action.data?.userData.ru,
        key: "2",
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/ru.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            Русский
          </>
        ),
        children: (
          <iframe
            name="contract-1"
            id="contract-1"
            src={`https://www.presta.ge/ru/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
      {
        key: "3",
        disabled: !action.data?.userData.en,
        label: (
          <>
            <ImageWithBasePath
              src="assets/img/flags/us.png"
              alt="img"
              height={10}
              width={14}
            />{" "}
            English
          </>
        ),
        children: (
          <iframe
            name={"contract-2"}
            id="contract-2"
            src={`https://www.presta.ge/en/contract?id=${action.data?.id}&fromAWS=1.2.3.4`}
            frameBorder={0}
            width="100%"
            height={600}
          />
        ),
      },
    ],
    [action],
  );

  const tableColumns = [
    {
      title: t("sales.agreement"),
      dataIndex: "id",
      render: (id, record) => (
        <Button type={"link"} onClick={() => setOpen(record)}>
          {id}
        </Button>
      ),
      fixed: "left",
      sorter: (a, b) =>
        a.id
          .split("-")[1]
          .localeCompare(b.id.split("-")[1], undefined, { numeric: true }),
    },
    {
      title: t("sales.client"),
      fixed: "left",
      dataIndex: "userData",
      render: (userData, row) => {
        return edit !== row._id ? (
          <Flex gap={2} align={"center"}>
            {getUserData(row, language).name}
            <Button
              type={"link"}
              onClick={() => setEdit(row._id)}
              size={"small"}
            >
              <FaPencil />
            </Button>
          </Flex>
        ) : (
          <Flex gap={2}>
            <Input
              defaultValue={row.comment}
              onBlur={async (e) => {
                await update(
                  { id: row._id, comment: e.target.value },
                  `/addEdit?table=invoices`,
                );
                await refetch();
              }}
            />
            <Button
              type={"dashed"}
              icon={<GiCheckMark onClick={() => setEdit("")} />}
            />
          </Flex>
        );
      },
      filters: list?.reduce((acc, row) => {
        const userData = getUserData(row, language);
        if (!acc.find((f) => f.text === userData.name)) {
          acc.push({ text: userData.name, value: userData.name });
        }
        return acc;
      }, []),
      filterSearch: true,
      onFilter: (value, record) => getUserData(record).name.startsWith(value),
      sorter: (a, b) => {
        const nameA = getUserData(a, language).name;
        const nameB = getUserData(b, language).name;
        return nameA.localeCompare(nameB);
      },
      // fixed: "left",
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.phone"),
      dataIndex: "userData",
      render: (userData, row) => getUserData(row).phone,
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.address"),
      dataIndex: "userData",
      render: (userData, row) => getUserData(row).address,
    },
    {
      title: t("sales.status"),
      dataIndex: "status",
      filters: [
        { text: "Не начато", value: "notStarted" },
        { text: t("sales.contractStatus.cutting"), value: "cutting" },
        { text: t("sales.contractStatus.assembly"), value: "assembly" },
        { text: t("sales.contractStatus.delivery"), value: "delivery" },
        { text: t("sales.contractStatus.installation"), value: "installation" },
        { text: t("sales.contractStatus.completed"), value: "completed" },
      ],
      filterSearch: true,
      render: (status, row) => getStatusButton(status, row._id),
      onFilter: (value, record) => {
        if (value === "notStarted") {
          return !record.status || record.status === "notStarted";
        }
        return record.status === value;
      },
    },
    {
      title: t("sales.history"),
      dataIndex: "history",
      render: (history, record) =>
        history?.length ? (
          <Button onClick={() => showHistory(history, record)} size={"small"}>
            {dayjs(new Date(history[0].date)).format("D MMM HH:mm")}
          </Button>
        ) : (
          <Button onClick={() => showHistory(history, record)} size={"small"}>
            {dayjs(new Date(record.created)).format("D MMM")}
          </Button>
        ),
    },
    {
      title: t("sales.date"),
      dataIndex: "created",
      render: (created) => dayjs(new Date(created)).format("D MMM"),
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
    },
    {
      title: t("sales.dueDate"),
      dataIndex: "created",
      render: (created, row) =>
        addBusinessDays(dayjs(new Date(created)), row.days || 15).format(
          "D MMM",
        ),
      sorter: (a, b) => {
        const dateA = addBusinessDays(dayjs(a.created), a.days || 15);
        const dateB = addBusinessDays(dayjs(b.created), b.days || 15);
        return dateA - dateB;
      },
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.price"),
      dataIndex: "price",
      render: (price) => `${NumberFormat(price)} ₾`,
      sorter: (a, b) => Number(a.price) - Number(b.price),
    },
    {
      title: t("sales.price_without"),
      dataIndex: "price",
      render: (price) => `${NumberFormat(Math.floor(price / 1.18))} ₾`,
      sorter: (a, b) => Math.floor(a.price / 1.18) - Math.floor(b.price / 1.18),
    },
    (permissions?.includes("WORK") ||
      permissions?.includes("FINANCE_ADMIN")) && {
      title: t("sales.team_salary"),
      dataIndex: "price",
      render: (price) => `${NumberFormat(Math.floor(price / 1.18) * 0.225)} ₾`,
      sorter: (a, b) =>
        Math.floor(a.price / 1.18) * 0.225 - Math.floor(b.price / 1.18) * 0.225,
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.teamPaid"),
      dataIndex: "totalTeamPayments",
      render: (paid, row) => {
        const toPay = Math.floor(row.price / 1.18) * 0.225;
        const payStatus = Math.floor(paid) >= Math.floor(toPay);
        return payStatus ? (
          <>
            <Button
              type={"primary"}
              shape={"round"}
              size={"small"}
              icon={<GiCheckMark />}
              color={"secondary"}
              onClick={() =>
                setTeamContract({ payments: row.teamPayments, record: row })
              }
            >
              {t(`sales.paid`)}
            </Button>
          </>
        ) : (
          <>
            <Button
              shape={"round"}
              size={"small"}
              addonBefore={<Plus />}
              color={"secondary"}
              onClick={() =>
                setTeamContract({ payments: row.teamPayments, record: row })
              }
            >
              {NumberFormat(paid || 0)} ₾
            </Button>
          </>
        );
      },
    },
    {
      title: t("sales.team"),
      dataIndex: "team",
      render: (team, record) => {
        const members = teams?.filter((user) => user.role === "team_member");
        return (
          <>
            <SelectUser
              multiple
              selected={team}
              disabled={!permissions.includes("ADMIN")}
              options={members?.map((team) => ({
                label: team.name,
                value: team._id,
              }))}
              onChange={async (value) => {
                await update(
                  { id: record._id, team: value },
                  `/addEdit?table=invoices`,
                );
                await refetch();
              }}
            />
          </>
        );
      },
      filters: teams
        ?.filter((user) => user.role === "team_member")
        .map((team) => ({
          text: extractName(team.name),
          value: team._id,
        })),
      filterSearch: true,
      onFilter: (value, record) => record.team?.includes(value),
    },
    (permissions?.includes("DESIGNER") ||
      permissions?.includes("FINANCE_ADMIN")) && {
      title: t("sales.designer_salary"),
      dataIndex: "price",
      render: (price) => `${NumberFormat(Math.floor(price / 1.18) * 0.075)} ₾`,
      sorter: (a, b) =>
        Math.floor(a.price / 1.18) * 0.075 - Math.floor(b.price / 1.18) * 0.075,
    },
    {
      title: t("sales.designer"),
      dataIndex: "designer",
      filterSearch: true,
      onFilter: (value, record) => {
        return record.designer?.includes(value);
      },
      filters: designers?.map((user) => {
        return {
          text: getName(user.id),
          value: user._id,
        };
      }),
      render: (designer, record) => {
        const designerList = designers?.map((user) => ({
          ...user,
          name: getName(user.id),
        }));
        return (
          <SelectUser
            selected={designer}
            disabled={!permissions.includes("ADMIN")}
            options={designerList?.map((team) => ({
              label: team.name,
              value: team._id,
            }))}
            onChange={async (value) => {
              await update(
                { id: record._id, designer: value },
                `/addEdit?table=invoices`,
              );
              await refetch();
            }}
          />
        );
      },
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.designerPaid"),
      dataIndex: "totalDesignerPayments",
      render: (paid, row) => {
        const toPay = Math.floor(row.price / 1.18) * 0.075;
        const payStatus = Math.floor(paid) >= Math.floor(toPay);
        return payStatus ? (
          <>
            <Button
              type={"primary"}
              shape={"round"}
              size={"small"}
              icon={<GiCheckMark />}
              color={"secondary"}
              onClick={() =>
                setContract({ payments: row.designerPayments, record: row })
              }
            >
              {t(`sales.paid`)}
            </Button>
          </>
        ) : (
          <>
            <Button
              shape={"round"}
              size={"small"}
              addonBefore={<Plus />}
              color={"secondary"}
              onClick={() =>
                setContract({ payments: row.designerPayments, record: row })
              }
            >
              {NumberFormat(paid || 0)} ₾
            </Button>
          </>
        );
      },
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: t("sales.left"),
      dataIndex: "total",
      sorter: (a, b) => a.price - a.total - (b.price - b.total),
      render: (total, row) => {
        const paid = row.price - total < 1;
        return paid ? t(`sales.paid`) : `${NumberFormat(row.price - total)} ₾`;
      },
    },
    permissions?.includes("FINANCE_ADMIN") && {
      title: "Действа",
      dataIndex: "actions",
      render: (_, row) => (
        <div className="action-table-data">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <CgFileDocument /> Контракт
                    </>
                  ),
                  onClick: () => {
                    console.info("View Profile", row);
                    setAction({ type: "view", data: row });
                  },
                },
                permissions?.includes("ADMIN") && {
                  key: "4",
                  label: (
                    <>
                      <FcMoneyTransfer /> Внести деньги
                    </>
                  ),
                  onClick: () => {
                    console.info("Remove", row);
                  },
                },
                permissions?.includes("ADMIN") && {
                  key: "2",
                  label: (
                    <>
                      <FaPencil /> Редактировать
                    </>
                  ),
                  onClick: () => {
                    console.info("Remove", row);
                  },
                },
                permissions?.includes("ADMIN") && {
                  key: "3",
                  label: "Удалить",
                  onClick: () => {
                    console.info("Share", row);
                  },
                },
              ].filter(Boolean),
            }}
            trigger={["hover"]}
            placement={"bottom"}
          >
            <EllipsisOutlined size={32} />
          </Dropdown>
        </div>
      ),
    },
  ].filter(Boolean);

  const dataSource = list
    ?.filter(
      (row) =>
        permissions.includes("ADMIN") ||
        row.team?.includes(_id) ||
        row.designer === _id,
    )
    ?.map((row) => ({
      ...row,
      key: row.id,
    }))
    .sort((a, b) => {
      // Helper function to get the comparison date
      const getCompareDate = (item) => {
        if (item.status === undefined) {
          return new Date(item.createdDate); // For items with undefined status
        }
        if (item.status !== "completed") {
          return new Date(item.createdDate); // For items where status is not completed
        }
        if (item.history?.length > 0) {
          return new Date(item.history[item.history.length - 1].date); // For items with history, use the most recent update
        }
        return new Date(item.createdDate); // Default to createdDate
      };

      // If one item is "completed" and the other is not, move "completed" to the end
      if (a.status === "completed" && b.status !== "completed") {
        return 1; // Move "a" down
      }
      if (a.status !== "completed" && b.status === "completed") {
        return -1; // Move "b" down
      }

      // Otherwise, sort by date
      const dateA = getCompareDate(a);
      const dateB = getCompareDate(b);

      return dateB - dateA; // Sort in descending order of date
    });

  const data = convertTableToVertical({
    columns: tableColumns,
    dataSource,
    size: width < 1024 ? "xs" : undefined,
  });

  useEffect(() => {
    setFilteredData(loadData);
  }, [loadData]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = loadData.filter((item) =>
      tableColumns.some((col) => {
        const cellValue = item[col.dataIndex];
        return (
          cellValue && cellValue.toString().toLowerCase().includes(searchValue)
        );
      }),
    );
    setFilteredData(filtered);
  };

  const designerCost = useMemo(
    () => (Math.floor(contract?.record?.price / 1.18) * 0.075 || 0).toFixed(2),
    [contract.record],
  );

  const teamCost = useMemo(
    () =>
      (Math.floor(teamContract?.record?.price / 1.18) * 0.225 || 0).toFixed(2),
    [teamContract.record],
  );

  useEffect(() => {
    setPaymentAmount(designerCost - contract?.record?.totalDesignerPayments);
  }, [designerCost, contract]);

  useEffect(() => {
    setTeamPaymentAmount(teamCost - teamContract?.record?.totalTeamPayments);
  }, [teamCost, teamContract]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Drawer
            open={!!open}
            width={window?.innerWidth < 768 ? "100%" : "70%"}
            onClose={() => setOpen(0)}
          >
            <ProductionTimeline
              key={open}
              days={open.days || 15}
              contractDate={open.created}
              status={open.status}
            />
          </Drawer>
          <Card className={"mb-2 pb-0"}>
            <Input.Search
              placeholder="Поиск"
              size={"large"}
              onSearch={handleSearch}
              style={{ marginBottom: 0 }}
              allowClear
            />
          </Card>

          {/*<div ref={mountRef}></div>*/}
          {/* /product list */}
          {list && (
            <div className="table-responsive">
              {data?.dataSource?.map((record, index) => (
                <React.Fragment key={index}>
                  <Table
                    columns={data.columns}
                    dataSource={record}
                    pagination={record?.length > 20}
                    scroll={{ x: "max-content" }}
                    showHeader={record?.length > 1}
                    loading={!list.length}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </div>
          )}
          {/* /product list */}
        </div>
      </div>

      <Modal
        width={600}
        title={`Оплата Бригаде по контракту ${teamContract.record?.id}`}
        open={!!teamContract.record}
        onCancel={() => {
          setTeamContract({ payments: [], record: null });
          setTeamPaymentAmount("");
        }}
        onOk={async () => {
          await addTeamPayment(
            {
              contractId: teamContract.record?._id,
              amount: teamPaymentAmount,
              date: paymentDate,
            },
            `/addEdit?table=teamPaid`,
          );
          setTeamPaymentAmount("");
          setTeamContract({ payments: [], record: null });
          await refetch();
        }}
        cancelText={"Отмена"}
        okText={"Сохранить"}
        okButtonProps={{
          disabled: !teamPaymentAmount,
          loading: saveTeamIsLoading,
        }}
      >
        <Row gutter={16} className={"mb-3"}>
          <Col span={6}>
            <Statistic
              title="К оплате"
              value={NumberFormat(teamCost)}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Оплачено"
              value={NumberFormat(teamContract?.record?.totalTeamPayments || 0)}
              precision={2}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Остаток"
              value={NumberFormat(
                designerCost - teamContract?.record?.totalTeamPayments || 0,
              )}
              precision={2}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Останеться"
              value={NumberFormat(
                Math.floor(teamCost) -
                  Math.floor(teamContract?.record?.totalTeamPayments || 0) -
                  Math.floor(
                    paymentAmount ||
                      Math.floor(teamCost) -
                        Math.floor(
                          teamContract?.record?.totalTeamPayments || 0,
                        ),
                  ),
              )}
              precision={2}
              suffix={"₾"}
            />
          </Col>
        </Row>

        <Flex gap={6}>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={dayjs()}
            onChange={(date) => setPaymentDate(date)}
            size={"large"}
            style={{ width: "30%", marginBottom: "10px" }}
          />
          <InputNumber
            placeholder="Сумма оплаты"
            value={teamPaymentAmount}
            key={teamContract?.record?._id}
            onChange={(e) => setTeamPaymentAmount(e)}
            style={{ marginBottom: "10px", width: "100%" }}
            size={"large"}
            addonAfter={"₾"}
            max={teamCost}
            min={0}
          />
        </Flex>

        {teamContract?.payments?.length ? (
          <Collapse
            ghost
            style={{ margin: 0, padding: 0 }}
            items={[
              {
                key: "1",
                label: "Детали",
                children: (
                  <Table
                    dataSource={teamContract.payments}
                    columns={[
                      {
                        title: "Дата",
                        dataIndex: "date",
                        key: "date",
                        render: (text) => dayjs(text).format("DD MMM YYYY"),
                      },
                      {
                        title: "Сумма",
                        dataIndex: "amount",
                        key: "amount",
                        render: (text) => `${NumberFormat(text)} ₾`,
                      },
                    ]}
                    pagination={false}
                    style={{ marginTop: "20px" }}
                  />
                ),
              },
            ]}
          />
        ) : null}
      </Modal>

      <Modal
        width={600}
        title={`Оплата дизайнеру по контракту ${contract.record?.id}`}
        open={!!contract.record}
        onCancel={() => {
          setContract({ payments: [], record: null });
          setPaymentAmount("");
        }}
        onOk={async () => {
          await addPayment(
            {
              contractId: contract.record?._id,
              amount: paymentAmount,
              date: paymentDate,
            },
            `/addEdit?table=designerPaid`,
          );
          setPaymentAmount("");
          setContract({ payments: [], record: null });
          await refetch();
        }}
        cancelText={"Отмена"}
        okText={"Сохранить"}
        okButtonProps={{ disabled: !paymentAmount, loading: saveIsLoading }}
      >
        <Row gutter={16} className={"mb-3"}>
          <Col span={6}>
            <Statistic
              title="К оплате"
              value={NumberFormat(designerCost)}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Оплачено"
              value={NumberFormat(contract?.record?.totalDesignerPayments || 0)}
              precision={2}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Остаток"
              value={NumberFormat(
                designerCost - contract?.record?.totalDesignerPayments || 0,
              )}
              precision={2}
              suffix={"₾"}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Останеться"
              value={NumberFormat(
                Math.floor(designerCost) -
                  Math.floor(contract?.record?.totalDesignerPayments || 0) -
                  Math.floor(
                    paymentAmount ||
                      Math.floor(designerCost) -
                        Math.floor(
                          contract?.record?.totalDesignerPayments || 0,
                        ),
                  ),
              )}
              precision={2}
              suffix={"₾"}
            />
          </Col>
        </Row>

        <Flex gap={6}>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={dayjs()}
            onChange={(date) => setPaymentDate(date)}
            size={"large"}
            style={{ width: "30%", marginBottom: "10px" }}
          />
          <InputNumber
            placeholder="Сумма оплаты"
            value={paymentAmount}
            key={contract?.record?._id}
            onChange={(e) => setPaymentAmount(e)}
            style={{ marginBottom: "10px", width: "100%" }}
            size={"large"}
            addonAfter={"₾"}
            max={designerCost}
            min={0}
          />
        </Flex>

        {contract?.payments?.length ? (
          <Collapse
            ghost
            style={{ margin: 0, padding: 0 }}
            items={[
              {
                key: "1",
                label: "Детали",
                children: (
                  <Table
                    dataSource={contract.payments}
                    columns={[
                      {
                        title: "Дата",
                        dataIndex: "date",
                        key: "date",
                        render: (text) => dayjs(text).format("DD MMM YYYY"),
                      },
                      {
                        title: "Сумма",
                        dataIndex: "amount",
                        key: "amount",
                        render: (text) => `${NumberFormat(text)} ₾`,
                      },
                    ]}
                    pagination={false}
                    style={{ marginTop: "20px" }}
                  />
                ),
              },
            ]}
          />
        ) : null}
      </Modal>

      <Modal
        width={600}
        title={`История заказа ${history.record?.id}`}
        open={!!history.record}
        onCancel={() => setHistory({ history: [], record: null })}
        onOk={async () => {
          await updateHistory(
            {
              contractId: history.record?._id,
              status: "comment",
              date: new Date(),
              comment,
              author: _id,
            },
            `/addEdit?table=history`,
          );
          setComment("");
          setHistory({ history: [], record: null });
          await refetch();
        }}
        cancelText={"Закрыть"}
        okText={"Отправить"}
        okButtonProps={{ disabled: !comment }}
      >
        <Timeline
          mode="left"
          pendingDot={history.record?.status === "completed" && <GiCheckMark />}
          key={history.record?.id}
          items={[
            {
              label: history.record?.created,
              children: "Подписание договора",
              dot: <BiPencil />,
            },
            ...history.history.map((item, index) => ({
              key: index,
              dot:
                item.status === "completed" ? (
                  <GiCheckMark />
                ) : item.status === "comment" ? (
                  <FaMessage />
                ) : index ===
                  history.history
                    .map((f, i) => ({ ...f, index: i })) // Attach original indices to items
                    .filter((f) => f.status !== "comment") // Filter for non-comment, non-completed items
                    .map((f) => f.index) // Extract indices of filtered items
                    .pop() ? ( // Get the last index
                  <Spin spinning size={"small"} />
                ) : (
                  <GiCheckMark />
                ),
              color: item.status === "completed" ? "green" : undefined,
              label: dayjs(new Date(item.date)).format("D MMM HH:mm"),
              children: (
                <div>
                  <Flex gap={6} align={"center"}>
                    {item.status === "cutting" ? (
                      <GiCircularSawblade />
                    ) : item.status === "assembly" ? (
                      <GiDrill />
                    ) : item.status === "delivery" ? (
                      <GiTruck />
                    ) : item.status === "installation" ? (
                      <GiFinishLine />
                    ) : (
                      ""
                    )}
                    {item.status === "comment" ? (
                      <div
                        style={{
                          borderLeft: "3px solid #ddd",
                          paddingLeft: 12,
                        }}
                      >
                        {item.comment}
                      </div>
                    ) : (
                      t(`sales.contractStatus.${item.status}`)
                    )}
                  </Flex>
                  <Flex gap={6} align={"center"}>
                    <BiUser /> {item.authorName}
                  </Flex>
                </div>
              ),
            })),
          ]}
        />
        <Input.TextArea
          placeholder="Добавить коментарий"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Modal>

      <Modal
        title={action.data?.id}
        open={action.type === "view"}
        onCancel={() => {
          setAction({ type: null, data: null });
        }}
        okText={"Print"}
        onOk={() => {
          const path =
            selectedTab === "2" ? "ru/" : selectedTab === "3" ? "en/" : "";
          window.open(
            `https://presta.ge/${path}contract?id=${action.data?.id}&fromAWS=1.2.3.4&print=true`,
            "targetWindow",
            `toolbar=no,
                          location=no,
                          status=no,
                          menubar=no,
                          scrollbars=yes,
                          resizable=yes,
                          width=SomeSize,
                          height=SomeSize`,
          );
        }}
        width="80%"
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          centered
          key={action.data?.id}
          onChange={setSelectedTab}
        />
      </Modal>
    </div>
  );
};

export default SalesList;
