function customRound(num) {
  const base = Math.floor(num); // Get the integer part (e.g., 4 for 4.1)
  const fraction = num - base; // Get the fractional part (e.g., 0.1 for 4.1)

  if (fraction <= 0.5) {
    return base + 0.5; // Round to 4.5
  } else {
    return base + 1; // Round to 5
  }
}

export default customRound;
