import React from "react";
import ThreeObjViewer from "./ObjViewer";
import { useControls } from "leva";

const Test = () => {
  const options = useControls("Сетка", {
    grid: true,
  });

  const rotation = useControls("Поворот", {
    x: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
    y: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
    z: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
  });

  const position = useControls("Позиция", {
    x: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
    y: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
    z: {
      value: 0,
      min: -20,
      max: 20,
      step: 0.3,
    },
  });

  return (
    <div>
      <div className="page-wrapper">
        <ThreeObjViewer
          objPath="/ss.obj"
          options={{ ...options, rotation, position }}
          key={options.grid}
        />
      </div>
    </div>
  );
};
export default Test;
