import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TDSLoader } from "three/examples/jsm/loaders/TDSLoader";

import { Progress } from "antd";

const SketchUpViewer = ({ options }) => {
  const [progress, setProgress] = useState(0);
  const mountRef = useRef(null); // Reference for the DOM container
  const rendererRef = useRef(null); // Reference to track if renderer exists
  const loadedObject = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    // Prevent multiple initializations of the renderer
    if (rendererRef.current) return;
    // Create the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    // Create the camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.6,
      300,
    );
    camera.position.x = 0;
    camera.position.y = 19;
    camera.position.z = 45;
    const gridHelper = new THREE.GridHelper(100, 50, 0x000000, 0xcccccc);
    gridHelper.position.y = 0;
    gridHelper.visible = options.grid;
    gridRef.current = gridHelper; // Store grid reference
    scene.add(gridHelper);

    // Create the renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true; // Enable shadows
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Optional: Soft shadows
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer; // Store renderer reference

    // Add lights
    const ambientLight = new THREE.AmbientLight(0x404040, 5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 20, 10);
    scene.add(directionalLight);

    const pointLight = new THREE.PointLight(0xffffff, 1, 100);
    pointLight.position.set(10, 10, 10);
    scene.add(pointLight);

    const addObject = (object) => {
      loadedObject.current = object;
      const box = new THREE.Box3().setFromObject(object);
      const size = box.getSize(new THREE.Vector3());

      // const center = box.getCenter(new THREE.Vector3());
      const maxDimension = Math.max(size.x, size.y, size.z);
      const scaleFactor = 50 / maxDimension; // Scale to fit a grid size of 100
      object.scale.set(scaleFactor, scaleFactor, scaleFactor);

      // Center the object
      object.position.set(0, 0, 0);
      // object.position.sub(center.multiplyScalar(scaleFactor)); // Adjust position to center

      // Place the object on the grid
      const adjustedBox = new THREE.Box3().setFromObject(object); // Recalculate bounding box after scaling
      const adjustedMin = adjustedBox.min; // Minimum point of the object
      object.position.y -= adjustedMin.y; // Lift the object to sit on the grid
      object.position.z -= adjustedMin.z; // Center the object on the grid

      const adjustedSize = adjustedBox.getSize(new THREE.Vector3());
      const largestDimension = Math.max(
        adjustedSize.x,
        adjustedSize.y,
        adjustedSize.z,
      );

      const fov = camera.fov * (Math.PI / 180); // Convert FOV to radians
      const cameraDistance = largestDimension / 2 / Math.tan(fov / 2); // Calculate distance
      camera.position.set(0, largestDimension / 2, cameraDistance * 1.5); // Position the camera dynamically
      camera.lookAt(new THREE.Vector3(0, largestDimension / 2, 0)); // Point the camera at the object
      scene.add(object);
    };

    // gridHelper.visible = grid;
    const loadModel = (filePath, fileType) => {
      if (fileType === "3ds") {
        const tdsLoader = new TDSLoader();
        tdsLoader.load(
          filePath,
          (object) => {
            addObject(object);
          },
          (xhr) => {
            setProgress((xhr.loaded / xhr.total) * 100);
          },
          (error) => console.error("Error loading .3ds file:", error),
        );
      } else if (fileType === "obj") {
        const mtlLoader = new MTLLoader();
        const objLoader = new OBJLoader();
        mtlLoader.load(
          "/files/2.mtl",
          (materials) => {
            materials.preload();
            objLoader.setMaterials(materials);
            objLoader.load("/files/2.obj", addObject, undefined, (error) =>
              console.error("Error loading object:", error),
            );
          },
          (xhr) => {
            setProgress((xhr.loaded / xhr.total) * 100);
          },
          (error) => console.error("Error loading materials:", error),
        );
      }
    };

    // Load materials and model

    loadModel("/files/1.3ds", "3ds");

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = true;
    controls.minDistance = 1;
    controls.maxDistance = 500;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Handle resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);

      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current = null;
      }
      controls.dispose();
    };
  }, [options.grid]);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.document.querySelector("canvas").style.display = "none";
  //   }
  // }, []);

  const setPositions = useCallback(() => {
    loadedObject.current.rotation.x = options.rotation.x;
    loadedObject.current.rotation.y = options.rotation.y;
    loadedObject.current.rotation.z = options.rotation.z;
    loadedObject.current.position.y = options.position.y;
    loadedObject.current.position.x = options.position.x;
    loadedObject.current.position.z = options.position.z;
  }, [options.rotation, options.position]);

  useEffect(() => {
    if (loadedObject.current) {
      setPositions();
    }
  }, [setPositions]);

  useEffect(() => {
    gridRef.current.visible = options.grid;
  }, [options.grid]);

  return (
    <>
      {progress < 100 && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100vh",
            background: "#fff",
            zIndex: 1,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%)",
            }}
          >
            <Progress type="circle" percent={progress} />
          </div>
        </div>
      )}
      <div ref={mountRef} style={{ width: "100vw", height: "100vh" }} />
    </>
  );
};

export default memo(SketchUpViewer);
